import { a as autobind } from './bundled-app.js';
import { D as Directive } from './bundled-Directive.js';
import { P as ProductManager } from './bundled-ProductManager.js';
import { H as HostChild } from './bundled-HostChild.js';
import './bundled-BehaviorSubject.js';

function convertSchemaToHtml(schema, options = {}) {
  let { scoped } = options;
  let html = '';
  if (typeof schema === 'string' || schema instanceof String) {
    schema = JSON.parse(schema);
  }

  if (typeof options === 'string' || options instanceof String || options === true) {
    scoped = options;
  }

  if (schema.type === 'root' && schema.children.length > 0) {
    if (scoped) {
      html += `
      <div class="${scoped === true ? `rte` : scoped}">
        ${convertSchemaToHtml(schema.children, options)}
      </div>
      `;
    } else {
      html += convertSchemaToHtml(schema.children, options);
    }
  } else {
    for (const el of schema) {
      switch (el.type) {
        case 'paragraph':
          html += buildParagraph(el, options);
          break
        case 'heading':
          html += buildHeading(el, options);
          break
        case 'list':
          html += buildList(el, options);
          break
        case 'list-item':
          html += buildListItem(el, options);
          break
        case 'link':
          html += buildLink(el, options);
          break
        case 'text':
          html += buildText(el, options);
          break
      }
    }
  }
  return html
}

function getClass(tag, classes) {
  if (classes && classes[tag]) {
    return classes[tag]
  } else {
    return null
  }
}

function outputAttributes(attributes) {
  if (!attributes && attributes?.class) return ''
  const ATTRIBUTE_SEPARATOR = ' ';
  return Object.keys(attributes)
    .map(key => {
      if (attributes[key]) {
        return `${ATTRIBUTE_SEPARATOR}${key}="${attributes[key]}"`
      }
    })
    .join('')
}

function createElement(tag, classes, content, attributes = {}) {
  attributes = { ...attributes, class: getClass(tag, classes) };
  return `<${tag}${outputAttributes(attributes)}>${content}</${tag}>`
}

function buildParagraph(el, options) {
  const { classes } = options;
  return createElement('p', classes, convertSchemaToHtml(el?.children, options))
}

function buildHeading(el, options) {
  const { classes } = options;
  const tag = `h${el?.level}`;
  return createElement(tag, classes, convertSchemaToHtml(el?.children, options))
}

function buildList(el, options) {
  const { classes } = options;
  const tag = el?.listType === 'ordered' ? 'ol' : 'ul';
  return createElement(tag, classes, convertSchemaToHtml(el?.children, options))
}

function buildListItem(el, options) {
  const { classes } = options;
  return createElement('li', classes, convertSchemaToHtml(el?.children, options))
}

function buildLink(el, options) {
  const { classes } = options;
  const attributes = {
    href: el?.url,
    title: el?.title,
    target: el?.target,
  };
  return createElement('a', classes, convertSchemaToHtml(el?.children, options), attributes)
}

function buildText(el, options) {
  const { classes, newLineToBreak } = options;
  if (el?.bold) {
    return createElement('strong', classes, el?.value)
  } else if (el?.italic) {
    return createElement('em', classes, el?.value)
  } else {
    return newLineToBreak ? el?.value?.replace(/\n/g, '<br>') : el?.value
  }
}

function e(e,t,n,o){var i,r=arguments.length,p=r<3?t:null===o?o=Object.getOwnPropertyDescriptor(t,n):o;if("object"==typeof Reflect&&"function"==typeof Reflect.decorate)p=Reflect.decorate(e,t,n,o);else for(var a=e.length-1;a>=0;a--)(i=e[a])&&(p=(r<3?i(p):r>3?i(t,n,p):i(t,n))||p);return r>3&&p&&Object.defineProperty(t,n,p),p}function t(e,t){if("object"==typeof Reflect&&"function"==typeof Reflect.metadata)return Reflect.metadata(e,t)}class ShippingInfo extends Directive{onAppInit(){this.productManager.stateChange$.subscribe({next:this.onStateChange});}onStateChange(e){let t=this.defaultTemplate.innerHTML.trim();e.selectedVariant?.metafields.pact.shipping_message_info&&(t=convertSchemaToHtml(e.selectedVariant.metafields.pact.shipping_message_info));let o=this.host.closest(".tooltip"),i=document.querySelector(o.getAttribute("tooltip-trigger"));i?.classList.toggle("textCta",!!t),i.disabled=!t,t&&(this.textHolder.innerHTML=t);}constructor(...e){super(...e),this.productManager=ProductManager.getInstance(this.host);}}ShippingInfo.selector="[shipping-info]",e([HostChild("[text-holder]"),t("design:type","undefined"==typeof HTMLElement?Object:HTMLElement)],ShippingInfo.prototype,"textHolder",void 0),e([HostChild("[generic-message]"),t("design:type","undefined"==typeof HTMLTemplateElement?Object:HTMLTemplateElement)],ShippingInfo.prototype,"defaultTemplate",void 0),e([autobind,t("design:type",Function),t("design:paramtypes",["undefined"==typeof ProductState?Object:ProductState]),t("design:returntype",void 0)],ShippingInfo.prototype,"onStateChange",null);

export { ShippingInfo };
